import topic from '../topic'
export const podtAPI = {
    getPodts: topic.podtTopic + '/get_podts',
    // getPodtsPpot: topic.podtTopic + '/get_podts_ppot',
    // getPodtsPpin: topic.podtTopic + '/get_podts_ppin',
    getPodtsV1: topic.podtTopic + '/get_podts_v1',
    getPodtById: topic.podtTopic + '/get_podt_by_id',
    addPodt: topic.podtTopic + '/add_podt',
    editPodt: topic.podtTopic + '/edit_podt',
    deletePodtByIds: topic.podtTopic + '/delete_podt_by_ids',
    get_all_podts_v1:topic.podtTopic + '/get_all_podts_v1', //批量开卡
    get_all_podts_v2:topic.podtTopic + '/get_all_podts_v2', //补  开卡
    getRfidPrcsByPodtId:topic.podtTopic + '/get_rfid_prcs_by_podt_id',
    rfid_finish:topic.podtTopic + '/rfid_finish', //完成
    getPodtsByMtrlId:topic.podtTopic + '/get_podts_by_mtrl_id',
}