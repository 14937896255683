<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MstfAddMain from "@/views/StockManagement/MstfManage/TabChild/MstfAddMain";
export default {
  name: "MstfAdd",
  components: {
    childTab1: MstfAddMain,
  },
  data() {
    return {
      activeName: "first",
    }
  },
  created(){
    
  }
}
</script>

<style scoped>

</style>