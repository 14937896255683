import topic from '../topic'
export const modrAPI = {
    getModrs: topic.modrTopic + '/get_modrs',
    getModrById: topic.modrTopic + '/get_modr_by_id',
    // getPodrProdMtrls: topic.podrTopic + '/get_podr_prod_mtrls',
    // getPodrProdPrcs: topic.podrTopic + '/get_podr_prod_prcs',
    addModr: topic.modrTopic + '/add_modr',
    editModr: topic.modrTopic + '/edit_modr',
    deleteModrByIds: topic.modrTopic + '/delete_modr_by_ids',
    modrShareAccts: topic.modrTopic + '/modr_share_accts',
    getModrMtrlsByMtrlId: topic.modrTopic + '/get_modr_mtrls_by_mtrl_id',
}