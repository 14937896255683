import topic from '../topic'
export const mtqrAPI = {
    getMtqrs: topic.mtqrTopic + '/get_mtqrs',
    addMtqr: topic.mtqrTopic + '/add_mtqr',
    deleteMtqrByIds: topic.mtqrTopic + '/delete_mtqr_by_ids',
    getMtqrById: topic.mtqrTopic + '/get_mtqr_by_id',
    getMtqrsByRecycleBin: topic.mtqrTopic + '/get_mtqrs_by_recycle_bin',
    cancelDelete: topic.mtqrTopic + '/cancel_delete',
    getMtrlByInfo: topic.mtqrTopic + '/get_mtrl_by_info',
    addMtrlToMtqr: topic.mtqrTopic + '/add_mtrl_to_mtqr',
}