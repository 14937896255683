<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('mstfForm')"
        @cancel="cancel"
        :stateFlag="false" 
    ></add-header>
    <el-form ref="mstfForm" :model="mstfForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="二维码编号" prop="mtqr_no">
            <el-input :disabled="disableFlag" @keydown.enter.native="inputDown" ref="inputs" maxlength="10" focus @input="mstfForm.mtqr_no=helper.keepEngNum(mstfForm.mtqr_no)" v-model="mstfForm.mtqr_no" placeholder="请填写二维码编号" show-word-limit>
              <!-- <div slot="append" @click="emptyInput"></div> -->
              <el-button slot="append" @click="emptyInput" type="info" class="vd_btn">清空</el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <div style="visibility: hidden;">11</div>
        </el-col>
        <el-col :md="8">
          <el-form-item label="现有库存">
            <el-input disabled maxlength="10" v-model="addNum" placeholder="当前二维码已入库存数量" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <el-input disabled maxlength="10" v-model="mstfForm.mtrl_no" placeholder="请填写物料编号" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_name" placeholder="请填写物料名称" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-input :disabled="true" v-if="mstfForm.mtrl_type === 0" v-model="mtrlType1"  show-word-limit placeholder="暂无物料类型"></el-input>
            <el-input :disabled="true" v-else-if="mstfForm.mtrl_type === 1" v-model="mtrlType2"  show-word-limit placeholder="暂无物料类型"></el-input>
            <el-input :disabled="true" v-else-if="mstfForm.mtrl_type === 2" v-model="mtrlType3"  show-word-limit placeholder="暂无物料类型"></el-input>
            <el-input :disabled="true" v-else v-model="mtrlType4"  show-word-limit placeholder="暂无物料类型"></el-input>
            <!-- <el-select disabled v-model="mstfForm.mtrl_type" placeholder="请选择物料类型" clearable>
              <el-option
                  v-for="item in mtrlType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料规格" prop="mtrl_spec">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_spec" placeholder="请填写物料规格" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="潘通色号" prop="mtrl_color">
            <el-input disabled maxlength="20" v-model="mstfForm.mtrl_color" placeholder="请填写潘通色号" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料单位" prop="mtrl_unit">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_unit" placeholder="请填写物料单位" show-word-limit>
            </el-input>
            <!-- <el-select disabled v-model="mstfForm.mtrl_unit" placeholder="请选择物料单位" clearable>
              <el-option
                v-for="item in mtrlTypeGroupOpt"
                :key="item.id"
                :label="item.param1"
                :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料门幅" prop="mtrl_width">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_width" placeholder="请填写物料门幅" show-word-limit>
              <template slot="append">米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="库位" prop="mtqr_bin">
            <!-- <el-input disabled maxlength="30" v-model="mstfForm.mtqr_bin_no" placeholder="请填写库位" show-word-limit>
            </el-input> -->
            <el-select :disabled="binFlag" v-model="mstfForm.mtqr_bin" placeholder="请选择库位" clearable>
              <el-option
                v-for="item in binList"
                :key="item.id"
                :label="item.param1"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="新增数量" prop="mtrl_num">
            <el-input v-model="mstfForm.mtrl_num" @change="mstfForm.mtrl_num = helper.calcPrice(mstfForm.mtrl_num,4,1000000)" @input="mstfForm.mtrl_num = helper.keepTNum1(mstfForm.mtrl_num)" maxlength="11" show-word-limit placeholder="请填写数量"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="订单产品物料" prop="podr_prod_mtrl_id">
            <el-select v-model="mstfForm.podr_prod_mtrl_id" placeholder="请选择订单产品物料" clearable>
              <el-option
                  v-for="item in podtList"
                  :key="item.podr_prod_mtrl_id"
                  :label="item.podtValue"
                  :value="item.podr_prod_mtrl_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> 
        <el-col :md="8">
          <el-form-item label="订单物料" prop="modr_mtrl_id">
            <el-select v-model="mstfForm.modr_mtrl_id" placeholder="请选择订单物料" clearable>
              <el-option
                  v-for="item in modrList"
                  :key="item.modr_mtrl_id"
                  :label="item.modr_no"
                  :value="item.modr_mtrl_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> 
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
     </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mstfAPI} from "@api/modules/mstf";
import {mtqrAPI} from "@api/modules/mtqr";
import {optnAPI} from "@api/modules/optn";
import {podtAPI} from "@api/modules/podt";
import {modrAPI} from "@api/modules/modr";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";

export default {
  name: "mstfAddMain",
  components: {
    addHeader,
    inputUser,
  },
  data() {
    return {
      rules:{
        mtqr_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        mtrl_num:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        mstf_length:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        mstf_width:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        mtqr_bin:[
          { required: true, trigger: 'change' , message: ' '},
        ],
      },
      mstfForm:{
        mstf_type:null,
        mtqr_no:null,
        mtrl_id:null,
        mtrl_no:null,
        mtrl_name:null,
        mtrl_unit:null,
        mtrl_type:null,
        mtrl_num:null,
        mtrl_width:null,
        mtrl_color:null,
        mtrl_spec:null,
        mtqr_bin:null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        status:0,
        key:0,
      },
      binList:[],
      mtrlType:[
        {id:0,label:'原面料'},
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
        {id:3,label:'加工面料'},
      ],
      mtrlTypeGroupOpt:[],
      title:'',
      binFlag:false,
      numFlag:false,
      mtrlType1:'原面料',
      mtrlType2:'辅料',
      mtrlType3:'包材',
      mtrlType4:'',
      disableFlag:false,
      addNum:null,
      podtList:[],
      modrList:[],
    }
  },
  watch: {
  },
  created() {
    this.initData()
  },
  mounted(){
    this.$refs.inputs.focus()
  },
  methods:{
    initData(){
      this.getMstfType()
      this.getBinList()
    },
    // 获取库位信息
    getBinList(){
      get(optnAPI.getAllContent,{perm_id : 10006})
      .then(res=>{
        if(res.data.code === 0){
          this.binList = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取单位
    getMstfType(){
      get(optnAPI.getAllContent,{perm_id : 10005})
      .then(res=>{
        if(res.data.code === 0) {
          this.mtrlTypeGroupOpt = res.data.data
        }
      }).catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取订单产品物料
    getPodtList(ids){
      get(podtAPI.getPodtsByMtrlId,{mtrl_id:ids})
      .then(res=>{
        if(res.data.code === 0) {
          this.podtList = res.data.data.list
          for(let i=0;i<this.podtList.length;i++){
            this.podtList[i].podtValue = this.podtList[i].podr_no + '  ' + this.podtList[i].prod_no 
          }
          let temp = {};
          temp.podtValue = '暂无';
          temp.podr_prod_mtrl_id = this.podtList.length;
          this.podtList.push(temp)
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取订单物料
    getModrList(ids){
      get(modrAPI.getModrMtrlsByMtrlId,{mtrl_id:ids})
      .then(res=>{
        if(res.data.code === 0) {
          this.modrList = res.data.data.list
          let temp = {};
          temp.modr_no = '暂无';
          temp.modr_mtrl_id = this.modrList.length;
          this.modrList.push(temp)
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存入库
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      this.mstfForm = Object.assign(this.mstfForm,staffForm)
      if(this.mstfForm.podr_prod_mtrl_id+1 === this.podtList.length){
         this.mstfForm.podr_prod_mtrl_id = null 
        }
        if(this.mstfForm.modr_mtrl_id+1 === this.modrList.length){
          this.mstfForm.modr_mtrl_id = null
        }
      post(mstfAPI.stockIn,this.mstfForm)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.$EventBus.$emit('uploading',12345)
          const permId = this.$route.query.perm_id
          this.jump(`/mstf_list?perm_id=${permId}`)
          this.resetForm('mstfForm')
          this.$refs.inputs.focus()
        }else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'库位中已有此物料'
          })
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          this.initData()
        }
      })
      .catch(()=>{
        this.$message({
            type:'error',
            message:'请输入正确的二维码编号'
          })
          this.resetForm('mstfForm')
      })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('mstfForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{ })
    },
    // 扫码按下enter键导入物料
    inputDown(){
      if(!this.mstfForm.mtqr_no){
        return this.$message.warning('请先输入二维码编号')
      }
      this.$refs.inputs.blur()
      get(mtqrAPI.getMtqrById,{mtqr_no:this.$refs.inputs.$options.propsData.value})
      .then(res => {                                                    
        if(res.data.code === 0){
          this.disableFlag = false
          this.mtrlImport(res.data.data.form)
        }else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'库位中已有此物料'
          })
        } else {
          let mg =res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          this.initData()
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 清空
    emptyInput(){
      this.resetForm('mstfForm')
      this.disableFlag = false
    },
    // 物料入库导入
    mtrlImport(val){
      if(val.mtqr_bin){
        this.binFlag = true
        this.mstfForm.mtqr_bin = val.mtqr_bin;
        this.mstfForm.mtrl_num = null;
        this.addNum = this.helper.haveFour(val.mtqr_num);
      }else{
        this.binFlag = false;
        this.mstfForm.mtqr_bin = null;
        this.mstfForm.mtrl_num = this.helper.haveFour(val.mtqr_num);
        this.addNum = '0.0000';
        console.log('123213',this.addNum);
      }
      this.mstfForm.mtqr_id = val.mtqr_id;
      this.mstfForm.mtrl_id = val.mtrl_id;
      this.mstfForm.mtrl_no = val.mtrl_no;
      this.mstfForm.mtrl_name = val.mtrl_name;
      this.mstfForm.mtrl_unit = val.mtrl_unit;
      this.mstfForm.mtrl_type = val.mtrl_type;
      this.mstfForm.mtrl_spec = val.mtrl_spec;
      this.mstfForm.mtrl_color = val.mtrl_color;
      this.mstfForm.mtrl_width = this.helper.haveFour(val.mtrl_width);
      this.mstfForm.mtqr_bin_no = val.mtqr_bin_no;
      this.getPodtList(this.mstfForm.mtrl_id)
      this.getModrList(this.mstfForm.mtrl_id)
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mstfForm.mtqr_bin = null
      this.binFlag = false
      this.addNum = null
    },
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_btn{
  color: #18c7ee !important;
}
</style>